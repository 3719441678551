import Layout from "../components/layout-main";
import { graphql } from "gatsby";

/** @jsx jsx */
import { jsx } from "theme-ui";

const TermsPage = ({ location, data }) => {
  return (
    <Layout
      location={location}
      pageTitle="Terms of Service"
      hideSubscribe={true}
    >
      <section className="aw-accent">
        <div className="aw-content">
          <h1>Terms of Service</h1>
          <p>
            <i>
              Last Updated:{" "}
              {new Date(data.markdownRemark.frontmatter.date)
                .toISOString()
                .slice(0, 10)}
            </i>
          </p>
          <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
        </div>
      </section>
    </Layout>
  );
};

export default TermsPage;

export const pageQuery = graphql`
  query TermsPageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/terms.md/" }) {
      html
      frontmatter {
        author
        date
        title
      }
    }
  }
`;
